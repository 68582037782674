import {Info} from '@ambler/andive'
import {Andiv} from '@ambler/andive-next'

export function ErrorCard({error}: {error: {message: string}}) {
  console.error(error)
  const message = (error && error.message) || "Vous n'êtes pas autorisé à accéder à ce contenu."

  return (
    <Andiv column p="8px">
      <Info.Block variant="error">
        <Info.Item item={message} />
      </Info.Block>
    </Andiv>
  )
}
